import React, { createContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface VideoContextType {
  videoState: any;
  setVideoState: Dispatch<SetStateAction<any>>;
}

const defaultValue: VideoContextType = {
  videoState: {},
  setVideoState: () => {},
};

export const VideoContext = createContext<VideoContextType>(defaultValue);

export const VideoProvider = ({ children }: { children: ReactNode }) => {
  const [videoState, setVideoState] = useState<any>({});

  return (
    <VideoContext.Provider value={{ videoState, setVideoState }}>{children}</VideoContext.Provider>
  );
};
